import { configureStore } from '@reduxjs/toolkit';
import { ControllerParams } from '@wix/yoshi-flow-editor';

import * as application from 'store/application';
import * as group from 'store/group';
import * as feed from 'store/feed';

import { FEED_WIDGET_WARMUP_DATA_KEY } from '../../../config/constants';

export function createStore(config: ControllerParams) {
  const { flowAPI } = config;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { window } = wixCodeApi;

  const isSSR = flowAPI.environment.isSSR;
  const warmupData = window.warmupData.get(FEED_WIDGET_WARMUP_DATA_KEY);

  const preloadedState = !isSSR ? warmupData : {};

  return configureStore({
    preloadedState,
    reducer: {
      application: application.reducer,
      group: group.reducer,
      feed: feed.reducer,
    },
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: flowAPI,
        },
      });
    },
  });
}

export type IRootStore = ReturnType<typeof createStore>;
export type IRootState = ReturnType<IRootStore['getState']>;
