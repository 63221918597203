import { createAsyncThunk } from '@reduxjs/toolkit';
import { FlowAPI } from '@wix/yoshi-flow-editor';

import * as api from 'api/feed';

import {
  ISubscribeParams,
  IUnsubscribeParams,
  IPinParams,
  IUnpinParams,
  IReactParams,
  IUnreactParams,
  IFetchParams,
} from './types';

export const subscribe = createAsyncThunk(
  'feed:subscribe',
  function (params: ISubscribeParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { httpClient } = thunkAPI.extra as FlowAPI;

    return httpClient.request(api.subscribe(groupId, feedItemId));
  },
);

export const unsubscribe = createAsyncThunk(
  'feed:unsubscribe',
  function (params: IUnsubscribeParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { httpClient } = thunkAPI.extra as FlowAPI;

    return httpClient.request(api.unsubscribe(groupId, feedItemId));
  },
);

export const pin = createAsyncThunk(
  'feed:pin',
  function (params: IPinParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { httpClient } = thunkAPI.extra as FlowAPI;

    return httpClient.request(api.pin(groupId, feedItemId));
  },
);

export const unpin = createAsyncThunk(
  'feed:unpin',
  function (params: IUnpinParams, thunkAPI) {
    const { groupId, feedItemId } = params;
    const { httpClient } = thunkAPI.extra as FlowAPI;

    return httpClient.request(api.unpin(groupId, feedItemId));
  },
);

export const react = createAsyncThunk(
  'feed:react',
  function (params: IReactParams, thunkAPI) {
    const { groupId, code, feedItemId } = params;
    const { httpClient } = thunkAPI.extra as FlowAPI;

    return httpClient.request(api.react(groupId, feedItemId, code));
  },
);

export const unreact = createAsyncThunk(
  'feed:unreact',
  function (params: IUnreactParams, thunkAPI) {
    const { groupId, code, feedItemId } = params;
    const { httpClient } = thunkAPI.extra as FlowAPI;

    return httpClient.request(api.unreact(groupId, feedItemId, code));
  },
);

export const fetch = createAsyncThunk(
  'feed:list',
  function (params: IFetchParams, thunkAPI) {
    const { groupId } = params;
    const { httpClient } = thunkAPI.extra as FlowAPI;

    return httpClient.request(
      api.fetch(groupId, {
        cursor: {
          limit: params.limit,
          cursor: params.cursor,
        },
      }),
    );
  },
);

export const fetchNext = createAsyncThunk(
  'feed:list-more',
  function (params: IFetchParams, thunkAPI) {
    const { groupId, cursor, limit = 10 } = params;
    const { httpClient } = thunkAPI.extra as FlowAPI;

    return httpClient.request(
      api.fetch(groupId, {
        cursor: {
          cursor,
          limit,
        },
      }),
    );
  },
);
